import React from 'react';
import Nabvar from '../Components/Navbar/Navbar'
import Hero from '../Components/Hero/Hero'
import Footer from '../Components/Footer/Footer'
import Nosotros from '../Components/Nosotros/Nosotros';
import Direccion from '../Components/Direccion/Direccion';
import Numeros from '../Components/Numeros/Numeros';
import Video from '../Components/Video/Video';
import TitelSection from '../Components/TitelSection/TitelSection';
import Buton from '../Components/Buton/Buton';
import TitleSection2 from '../Components/TitleSection2/TitleSection2';
export default function IndexLayout() {




    return (
        <div >
            <Nabvar />
            <Hero />
            <Buton />
            <Nosotros />
            <TitelSection title='Dirección General' />
            <Direccion />
            <Numeros />
            <Video />
            <TitleSection2 />
            <Footer />
        </div>
    );
}
