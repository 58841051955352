import React, { useEffect, useState, useRef } from 'react';
import './Footer.css'
import { Link as Anchor } from 'react-router-dom';
import logo from '../../images/logo.png'
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
export default function Footer() {
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [contactos, setContactos] = useState([]);
    useEffect(() => {
        cargarProductos();

    }, []);
    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const categoriasMap = new Map();
                data.productos.forEach(producto => {
                    const categoria = producto.categoria;
                    if (categoriasMap.has(categoria)) {
                        categoriasMap.get(categoria).push(producto);
                    } else {
                        categoriasMap.set(categoria, [producto]);
                    }
                });
                const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));
                setCategorias(categoriasArray?.slice(0, 3));
                setProductos(data.productos?.slice(0, 3));
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    useEffect(() => {
        cargarContacto();

    }, []);


    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse()[0] || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


    return (
        <div className='FooterContain'>
            <div className='Footer'>
                <div className='footerText'>
                    <h2>AL BY LA BRUNA</h2>
                    <Anchor to='' >Inicio</Anchor>
                    <Anchor to='' >Trabajamos con las mejores Marcas</Anchor>
                    <Anchor to='' >Nosotros</Anchor>
                    <Anchor to='' >Video Institucional</Anchor>
                    <Anchor to='' >Contacto</Anchor>
                </div>
                <div className='footerText'>
                    <h3>CASA CENTRAL</h3>
                    <Anchor to='' >Av. Francisco de Aguirre 2250, San Miguel de Tucumán, Tucumán - T4001</Anchor>
                    <Anchor to='' >0381 427-7768</Anchor>
                    <Anchor to='' className='email' >contacto@antoniolabruna.com.ar</Anchor>
                    <Anchor to='' >Lunes a Viernes 08:00 a 12:30,Lunes</Anchor>
                </div>
                {/* 
                <div className='footerText'>
                    <h3>SUCURSAL BS. AS.</h3>
                    <Anchor to='' ></Anchor>
                    <Anchor to='' ></Anchor>
                    <Anchor to='' className='email'>contacto@antoniolabruna.com.ar</Anchor>
                    <Anchor to='' ></Anchor>
                </div> */}
                <div className='footerText'>
                    <h3>SUCURSAL SALTA</h3>
                    <Anchor to='' >Roldán 53, Salta - A4400</Anchor>
                    <Anchor to='' >0387 431-3856</Anchor>
                    <Anchor to='' className='email'>contacto@antoniolabruna.com.ar</Anchor>
                    <Anchor to='' >Lunes a Viernes 08:00 a 12:30,Lunes</Anchor>
                </div>
            </div>

            <hr />
            {/* <p>

                COPYRIGHT © 2022 <span>DIGITALMA S.A.S..</span> TODOS LOS DERECHOS RESERVADOS.
                El uso de este sitio web implica la aceptación de los Términos y Condiciones y de las Políticas de Privacidad de Antonio F. La Bruna S.R.L. Las fotos son a modo ilustrativo. El pedido de cualquiera de los productos publicados está sujeta a la verificación de stock. | <strong>Términos y Condiciones</strong>

            </p> */}
            <p>

                COPYRIGHT © 2024 GTS TECNO
            </p>
        </div>
    )
}
