import React from 'react';
import './TitleSection2.css';

export default function TitleSection2() {
    return (
        <div className='TitleSection2'>
            <h1>
                Página momentánea
            </h1>
            <h2>
                Estamos trabajando para una nueva experiencia
            </h2>
        </div>
    );
}
