import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link as Anchor } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import logo from '../../images/logo.png';
import 'swiper/swiper-bundle.css';
import './Navbar.css';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Define el punto de scroll en el que deseas activar la clase scroll
            const scrollThreshold = 10;
            if (scrollPosition > scrollThreshold) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header>
            <nav className={scroll ? 'scroll' : ''}>
                <Anchor to={`/`} className='logo'>
                    <img src={logo} alt="logo" />
                </Anchor>

                <div className='enlaces'>
                    <Anchor to={`/`} onClick={() => {
                        document.querySelector('.HeroContain').scrollIntoView({ behavior: 'smooth' });
                    }}>Inicio</Anchor>

                    <Anchor to={`/`} onClick={() => {
                        document.querySelector('.Nosotros').scrollIntoView({ behavior: 'smooth' });
                    }}>Nosotros</Anchor>
                    <Anchor to={`/`} onClick={() => {
                        document.querySelector('.video').scrollIntoView({ behavior: 'smooth' });
                    }}>Video Institucional</Anchor>
                    <Anchor to={`/`} onClick={() => {
                        document.querySelector('.FooterContain').scrollIntoView({ behavior: 'smooth' });
                    }}>Contacto</Anchor>
                    <Anchor to={`https://antoniolabrunaweb.com.ar/account/login `} target='blank' >Nuevo Ecommerce</Anchor>
                </div>





                <div className='deFLexNavs'>
                    <FontAwesomeIcon icon={faSearch} className='search-icon' />
                    <div className={`nav_toggle  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">

                        <div className='enlaces2'>
                            <Anchor to={`/`} onClick={() => {
                                document.querySelector('.HeroContain').scrollIntoView({ behavior: 'smooth' });
                            }}>Inicio</Anchor>

                            <Anchor to={`/`} onClick={() => {
                                document.querySelector('.Nosotros').scrollIntoView({ behavior: 'smooth' });
                            }}>Nosotros</Anchor>
                            <Anchor to={`/`} onClick={() => {
                                document.querySelector('.video').scrollIntoView({ behavior: 'smooth' });
                            }}>Video Institucional</Anchor>
                            <Anchor to={`/`} onClick={() => {
                                document.querySelector('.FooterContain').scrollIntoView({ behavior: 'smooth' });
                            }}>Contacto</Anchor>
                            <Anchor to={`https://antoniolabrunaweb.com.ar/account/login `} target='blank' >Nuevo Ecommerce</Anchor>
                        </div>
                    </div>
                </Modal>
            </nav>
        </header>
    );
}
