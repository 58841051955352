import React from 'react'
import './Video.css'
export default function Video() {
    return (
        <div className='video'>

            <iframe src="https://www.youtube.com/embed/aciO2DkQSvg?si=99biiyqSMcrXIHlC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>
    )
}
